/**
 * Site header
 */
.site-header {
    height: 500px;
    text-align: start;  
    background: url(/assets/images/banner.png);
    
    background-position-x: 50%;
    background-size: auto 500px;


    a {
      border-bottom: none;
    }
	
    @include media-query($phone) {
      height: 250px;
	  background-size: auto 250px;
    }
}

.site-title {
    display: inline-block;
    padding: 5px 15px 5px 15px;
    margin-top: 55px;
    background: $blue-color;
    color: $white-color;
    font-size: 180%;

      &:hover {
        color: $white-color;
        text-decoration: none;
      }

      &:visited {
        color: $white-color;
      }
	  
      @include media-query($phone) {
		font-size: 100%;
      }
}

.site-nav {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
    color: $blue-color;

    a {
      border-bottom: none;
    }

    .page-link {
      padding: 10px;
      line-height: $base-line-height;

      // Gaps between nav items, but not on the first one
      &:not(:first-child) {
          margin-left: 20px;
      }

      &:hover {
        color: $blue-color-dark;
        background: $blue-color-light;
        text-decoration: none;
      }
    }

    hr {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        height: 2px;
        border: 0;
        background-color: $blue-color;

        width: 65%;

        @include media-query($phone) {
          width: 30%;
        }
    }
}

/**
 * Site footer
 */
.site-footer {
    margin-top: 20px;
    padding: $spacing-unit 0;
}

/**
 * Content
 */

.post-meta {
  margin: 1rem 0 1rem 0;
  color: $blue-color;
}

.post-list {
  list-style: none;
  margin-left: 0;

  .li, div {
    padding: 5px 0;
  }

  .post-link {

    .post-title {

    }

    .post-date {
      color: $blue-color-light;
      float: right;
    }

    &:visited {
      color: $blue-color;
    }

    &:hover {
      color: $blue-color;
      text-decoration: none;
    }
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  p {
    font-size: 100%;
    margin: 2rem 0 2rem 0;
  }

  h1 {
      font-size: 160%
  }

  h2 {
      font-size: 150%;
  }

  h3 {
      font-size: 140%;
  }

  h4 {
      font-size: 130%;
  }
}
