/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    line-height: $base-line-height;
    font-size: $base-font-size;
    font-weight: 400;
    color: $light-text-color;
    background-color: $light-background-color;
    -webkit-text-size-adjust: 100%;

    @include media-query($phone) {
      font-size: 16px;
    }
	
	@media(prefers-color-scheme: dark) {
		color: $dark-text-color;
		background-color: $dark-background-color;
	}
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}

/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  color: $blue-color;
  margin-top: 10px;
  font-weight: 400;
}

/**
 * Links
 */
a {
    color: $blue-color;
    text-decoration: none;
    border-bottom: 2px solid $blue-color-light;

    &:visited {
        color: darken($blue-color, 15%);
    }

    &:hover {
        border-bottom: 2px solid $blue-color;
    }
}

/**
 * Blockquotes
 */
blockquote {
    color: $blue-color-dark;
    border-left: 4px solid $blue-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;
    background: $blue-color-light;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: $grey-color-light;

}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

/**
 * Container
 */
.container {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;

    @extend %clearfix;

    @include media-query($desktop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}
