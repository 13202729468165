@charset "utf-8";

// Our variables
$base-font-family:  'Avenir Next', 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-size:    24px;
$small-font-size:   $base-font-size * 0.875;
$base-line-height:  1.6;
$spacing-unit:      30px;

$dark-text-color: #fdfdfd;
$dark-background-color: #111;
$light-text-color:        #111;
$light-background-color:  #fdfdfd;

$white-color:       #fdfdfd;

$blue-color:        #15a0db;
$blue-color-light:  lighten($blue-color, 25%);
$blue-color-dark:   darken($blue-color, 25%);

$grey-color:        #828282;
$grey-color-light:  lighten($grey-color, 35%);
$grey-color-dark:   darken($grey-color, 25%);

// Width of the content area
$content-width:     800px;

$phone:             420px;
$desktop:           800px;

// Using media queries with like this:
// @include media-query($desktop) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "bigfoot-default"
;
